/* General Styles */
.title {
    background-color: var(--clr-theme);
    width: fit-content;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #fff;
    margin-top: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  
  .table-container {
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  }
  
  .heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }
  
  .user-list-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-box {
    display: flex;
    align-items: center;
    background-color: #d5d5d522;
    border-radius: 4px;
    padding: 10px 10px;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  }
  
  .search-box input {
    border: none;
    background-color: transparent;
    outline: none;
    flex-grow: 1;
    font-size: 16px;
    color: #333;
  }
  
  .search-box input::placeholder {
    color: #777;
  }
  
  .pagination {
    display: grid;
    place-items: center;
    margin: 1rem 0;
  }
  
  .loder {
    display: grid;
    place-items: center;
    padding: 2rem;
  }
  
  /* Table Styles */
  .beat_table {
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.2);
  }
  
  .MuiTableCell-head {
    background-color: var(--clr-theme);
    color: #fff;
    font-weight: bold;
    text-align: center;
    border-right: 1px solid #ddd;
  }
  
  .MuiTableCell-body {
    text-align: center;
    font-size: 14px;
  }
  
  .MuiTableCell-root {
    border-right: 1px solid #ddd;
  }
  
  .MuiTableRow-root {
    background-color: #fff;
  }
  
  .MuiTableRow-root:hover {
    background-color: #f2f7fb;
  }
  
  .MuiTableHead-root th {
    background-color: var(--clr-theme);
    color: #fff;
  }
  
  /* Data Not Found Styling */
  .data-not-found {
    text-align: center;
    font-size: 1.2rem;
    color: #555;
    margin-top: 2rem;
  }
  
  /* Loading Animation */
  @keyframes loder {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  .loder-icon {
    width: 50px;
    height: 50px;
    border: 6px solid rgba(0, 0, 0, 0.1);
    border-top-color: var(--clr-theme);
    border-radius: 50%;
    animation: loder 1s linear infinite;
  }
  
  /* Custom Styles for Chips */
  .chip-container {
    display: flex;
    gap: 0.5rem;
  }
  
  .chip {
    cursor: pointer;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    background-color: #e0e0e0;
  }
  
  .chip.selected {
    background-color: var(--clr-theme);
    color: #fff;
  }
  
  /* Astrologer Action Button Styles */
  .astrologer-action-btn {
    display: flex;
    gap: 1rem;
    cursor: pointer;
    color: var(--clr-theme);
  }
  
  /* Button and Icon Adjustments */
  .beat_left {
    display: flex;
    gap: 10px;
  }
  
  .add-button {
    display: flex;
    align-items: center;
    background-color: var(--clr-theme);
    color: #fff;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .add-button svg {
    margin-right: 5px;
  }
  
  .back-button { 
    background-color: var(--clr-theme);
    width: fit-content;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 1.5rem;
    cursor: pointer;
  }
  
  
  .admin_container {
    padding: 2rem 4rem;
  }
  
  .form-login-btn {
    padding-top: 3rem;
    width: 25%;
    margin: auto;
  }