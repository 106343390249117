.profile-page {
  width: 100%;
  padding: 1rem 1rem;
}
.profile-container {
  padding: 2rem 1rem;
}
.form-login-btn {
  padding-top: 3rem;
  width: 25%;
  margin: auto;
}
.profile_details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  /* margin-bottom: 2rem; */
}
.profile_details .avatar {
  position: relative;
}

.avatar .camera_icon {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 3rem;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 50%;
  border: 1px solid var(--main-color);
  cursor: pointer;
}
.showHideEye {
  color: rgb(0, 0, 0);
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
}
