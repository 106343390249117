/* General Styles */
.title {
    background-color: var(--clr-theme);
    width: fit-content;
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 0.5rem;
    cursor: pointer;
  }
  
  /* Card Container */
  .admin_container {
    padding: 2rem 4rem;
  }
  
  /* Input Styles */
  .admin_form input {
    margin: 0.6rem 0;
    padding: 0.8rem 0.6rem;
    width: 100%;
    font-size: 0.9rem;
    border-radius: 0.5rem;
    border: none;
    outline: none;
    background: #f2f7fb;
    box-sizing: border-box;
  }
  
  /* Button Styles */
  .btn-outline-dark {
    background: #dddddd 0 0 no-repeat padding-box;
    border: none;
    color: #000;
    margin-right: 6px;
    opacity: 1;
    padding: 0.4rem 1rem;
    border-radius: 5px;
  }
  
  .btn-outline-dark:hover {
    background: #0f52ba 0 0 no-repeat padding-box;
    color: #fff;
    opacity: 1;
    cursor: pointer;
  }
  
  /* Grid Layout */
  .user-list-heading {
    justify-content: space-between;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-block: 0rem 1rem;
    align-items: center;
  }
  
  /* Custom Button */
  .sample_csv_btn a {
    display: inline-block;
    background-color: var(--clr-theme);
    margin: 4px;
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: #fff;
    border-radius: 3px;
  }
  