.user-list-title {
    font-size: 27px;
    line-height: 32px;
    color: black;
}

.container {
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    display: flex;
    justify-content:space-between;
    gap:50px;
    margin-top: 30px;
}

.chat-container {
    display: flex;
    justify-content: center;
    align-items: end;
    gap:20px;
    position: relative;

}

.call-container {
    display: flex;
    justify-content: center;
    align-items: end;
    gap:20px;
    position: relative;


}

.slab-range-field {
    margin-top: 20px;
}



