/* popup */

.dialog_title {
  font-weight: bold;
  margin: auto;
}

.employee_gl_popup {
  background-color: #fff;
  font-size: 1rem;
  padding: 0.7rem 2rem;
  border-radius: 0.3rem;
  margin: auto;
  text-align: center;
  width: 50%;
  margin-top: 1.5rem;
  padding: 0.8rem 1rem;
  cursor: pointer;
  color: #000;
  border: 2px solid #000;
}
.employee_gl_popup_del {
  background-color: red;
  color: #fff;
  font-size: 1rem;
  padding: 0.7rem 2rem;
  border-radius: 0.3rem;
  margin: auto;
  text-align: center;
  width: 50%;
  margin-top: 1.5rem;
  padding: 0.8rem 1rem;
  cursor: pointer;
}
.employee_gl_popup_del:hover {
  background-color: #a00808;
}
.title-verify {
  text-align: center;
}
.title-verify h3 {
  font: normal normal 600 29px/22px;
}
.title-verify p {
  color: #777;
  font-size: 15px;
  font-weight: lighter;
  margin-block: 5px;
  font-weight: 500;
}
.veryfy-btn {
  background: var(--clr-primary);
  padding: 0.6rem 2rem;
  margin-block: 1rem;
  border-radius: 3px;
  color: #fff;
  align-items: center;
  width: 100%;
  width: fit-content;
  margin: auto;
  align-items: center;
  text-align: center;
  display: grid;
  cursor: pointer;
}
