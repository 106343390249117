.title {
  background-color: var(--clr-theme);
  width: fit-content;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.active_admin {
  background-color: #36d200;
  color: #fff;
  border-radius: 1rem;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  display: inline-block;
}

.inactive_admin {
  background-color: #f83838;
  color: #fff;
  border-radius: 1rem;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  display: inline-block;
}

.pending_box_admin {
  background-color: #f2f751;
  color: #000000;
  border-radius: 1rem;
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  display: inline-block;
}

.card-header {
  padding: 1rem;
  margin-block: 2rem;
}

/* popup */

.dialog_title {
  font-weight: bold;
  margin: auto;
}

.employee_gl_popup {
  background-color: #fff;
  font-size: 1rem;
  padding: 0.7rem 2rem;
  border-radius: 0.3rem;
  margin: auto;
  text-align: center;
  width: 50%;
  margin-top: 1.5rem;
  padding: 0.8rem 1rem;
  cursor: pointer;
  color: #000;
  border: 2px solid #000;
}

.employee_gl_popup_del {
  background-color: red;
  color: #fff;
  font-size: 1rem;
  padding: 0.7rem 2rem;
  border-radius: 0.3rem;
  margin: auto;
  text-align: center;
  width: 50%;
  margin-top: 1.5rem;
  padding: 0.8rem 1rem;
  cursor: pointer;
}

.employee_gl_popup_del:hover {
  background-color: #a00808;
}

/* add user css  */
.admin_container {
  padding: 2rem 4rem;
}

.pagination {
  display: grid;
  place-items: center;
  margin-block: 1rem;
}

.admin_feilds {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.admin_feilds .left,
.admin_feilds .right {
  flex: 1;
  padding-top: 0;
}

.admin_form input {
  margin: 0.6rem 0;
  padding: 0.8rem 0.6rem;
  width: 100%;
  font-size: 0.9rem;
  border-radius: 0.5rem;
  border: none;
  outline: none;
  background: #f2f7fb;
  box-sizing: border-box;
}

.form-login-btn {
  padding-top: 3rem;
  width: 25%;
  margin: auto;
}

.addedcation_details {
  color: #1b74e4;
  font-size: 20px;
  padding: 8px 4px 5px 0;
  cursor: pointer;
}

.btn-outline-dark {
  background: #dddddd 0 0 no-repeat padding-box;
  border: #ffdead;
  color: #000;
  margin-right: 6px;
  opacity: 1;
  padding: 0.4rem 1rem;
  border-radius: 5px;
}

.btn-outline-dark:hover {
  background: #0f52ba 0 0 no-repeat padding-box;
  color: #fff;
  opacity: 1;
  cursor: pointer;
}

.user-form-btn {
  margin-block: 1rem;
}

.education-content {
  display: flex;
  justify-content: space-between;
  margin-block: 0.5rem;
  border: 1px solid #777;
  padding: 0.5rem;
  border-radius: 7px;
  align-items: center;
}

.main-heading-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-box {
  align-items: center;
  background-color: #d5d5d522;
  border-radius: 4px;
  color: #777;
  display: flex;
  flex-grow: 1;
  padding: 10px 5px;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
}

.search-box input {
  background-color: initial;
  border: none;
  color: #777;
  flex-grow: 1;
  font-size: 18px;
  outline: none;
}

.user-list-heading {
  justify-content: space-between;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-block: 0rem 1rem;
  align-items: center;
}

.filter-btn {
  background-color: var(--clr-primary);
  color: white;
  max-width: fit-content;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  cursor: pointer;
}

.user-list-search {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.astrologer-action-btn {
  padding: 0;
  display: flex;
}

.astrologer-action-btn span {
  display: inline-block;
  background-color: var(--clr-theme);
  margin: 4px;
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: #fff;
  border-radius: 3px;
}

.astro_add_heading {
  width: 100%;
  padding: 0.5rem 1.5rem;
}

.filter-container {
  display: flex;
  align-items: end;
  margin: 2rem 0;
  border: 1px solid lightgray;
  padding: 1rem;
}

.filter-left {
  flex-grow: 1;
}

.filter_btn {
  background-color: var(--clr-theme);
  padding: 0.4rem 1rem;
  color: #fff;
  display: grid;
  place-items: center;
  cursor: pointer;
  border-radius: 0.5rem;
}

.input_eye_parent {
  position: relative;
}

.input_eye_parent .cross {
  position: absolute;
  right: 3%;
  top: 50%;
  transform: translate(0, -50%);
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
}

/* Drag and Drop Box */
.drag_drop_box {
  position: relative;
  height: 150px;
  width: 100%;
  line-height: 145px;
  border: 2px dashed #ccc;
  text-align: center;
  input[type="file"] {
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0;
    top: 0;
    left: 0;
  }
}

.astro-pricing-container {
  margin-top: 30px;
}
.pricingFetchButtonBefore{
  background-color: rgb(159, 159, 159);
  width: fit-content;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  margin-bottom: 0.5rem;
  cursor: pointer;
  border: none;


}
.pricingFetchButton{
  background-color: rgb(140, 100, 180);
  width: fit-content;
  text-align: center;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  margin-bottom: 0.5rem;
  cursor: pointer;
  border: none;


}
