@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --bg-primary: #ffffff;
  --clr-primary: linear-gradient(129.99deg, #ffddb5 0%, #fff9ed 47.43%);
  --clr-theme: #8c64b4;
  /* --clr-primary: #f6b884; */
  --roboto: "Roboto", sans-serif;
}
label {
  padding-bottom: 1%;
}
