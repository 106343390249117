.invoice {
    border: 2px solid black;
    margin: 1rem auto 4rem;
    padding: 1rem;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    max-width: 900px;
  }
  
  h4 {
    font-weight: 500;
    font-size: 1.5rem;
  }
  .info-box {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 1rem;
    text-align: left;
  }
  .info-box .addr-info {
    width: 50%;
  }
  /* =================== */
  .inv-table {
    width: 100%;
    border-collapse: collapse;
    border-color: black;
    margin-top: 1rem;
    text-align: left;
  }
  .inv-table td,
  .inv-table th {
    vertical-align: bottom;
    padding: 5px;
    border-width: 1px;
  }
  .inv-table th {
    text-align: center;
  }
  .addr {
    text-decoration: underline;
  }
  
  .b {
    font-weight: 600;
  }
  .fs-20{
    font-size: 20px;
  }
  .mt{
    margin-top: .5rem;
  }
  .bdr-b {
    border-bottom: 1px solid black;
  }
  
  .bdr-t {
    border-top: 1px solid black;
  }
  
  .signature_sign {
    width: fit-content;
    margin-top: 2rem;
  }
  .sign {
    width: fit-content;
    border-top: 1px solid black;
  }
  
  .other-info {
    text-align: left;
    margin-top: 1rem;
    width: 65%;
  }